import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { BookComponentComponent } from './book-component/book-component.component';
import { BooksComponentComponent} from './books-component/books-component.component';
import {AvailableBook} from "../model/AvailableBook";
import { AddBookComponent } from './add-book/add-book.component';
import * as $ from "jquery";
import { VoteBooksComponent } from './vote-books/vote-books.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SortComponent } from './sort/sort.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TimelineComponent } from './timeline/timeline.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    BookComponentComponent,
    BooksComponentComponent,
    AddBookComponent,
    VoteBooksComponent,
    ToolbarComponent,
    SortComponent,
    DisclaimerComponent,
    TimelineComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      {path: '', component: HomeComponent, pathMatch: 'full'},
      {path: 'counter', component: CounterComponent},
      {path: 'fetch-data', component: FetchDataComponent},
    ]),
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

