import {Component, Input, OnInit} from '@angular/core';
import {BookLinkService} from "../book-link.service";
import {AvailableBook} from "../../model/AvailableBook";
import {Observable} from "rxjs";
import * as $ from "jquery";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ToolbarComponent} from "../toolbar/toolbar.component";

@Component({
  selector: 'app-books-component',
  templateUrl: './books-component.component.html',
  styleUrls: ['./books-component.component.css']
})
export class BooksComponentComponent implements OnInit {
  // get AllVotes(): FormArray {
  //   return this.Votes.get('AllVotes') as FormArray;
  // }
  availableBooks: AvailableBook[];
  constructor(private bookLink: BookLinkService) { }

  ngOnInit(): void {
    this.getBooks("DESC", "Score", false);
  }

  getBooks(sortOrder: string, sortField: string, includeRead: boolean): void {
    this.bookLink.getBooks(sortOrder, sortField, includeRead)
      .subscribe(
        {
          next: books => {
            this.availableBooks = books;
          },
          complete: () =>
            setTimeout(function(){$('textarea.Convertible').each(function(){$(this).height(this.scrollHeight);})}, 0)
        });
  }
}
