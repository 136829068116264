import {Component, Input, OnInit} from '@angular/core';
import {VoteBooksComponent} from "../vote-books/vote-books.component";
import {BooksComponentComponent} from "../books-component/books-component.component";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  @Input() public BooksComponent: BooksComponentComponent;
  public VoteTool: VoteBooksComponent
  constructor() { }

  ngOnInit() {
  }

}
