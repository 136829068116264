import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import {Validators, FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {BookLinkService} from "../book-link.service";
import {AvailableBook} from "../../model/AvailableBook";

@Component({
  selector: 'app-add-book',
  templateUrl: './add-book.component.html',
  styleUrls: ['./add-book.component.css', '../toolbar/toolbar.css']
})
export class AddBookComponent implements OnInit {
  NewBookData = this.formBuilder.group({
    NewBookTitle: '',
    NewBookAuthor: '',
    NewBookDescription: '',
    NewBookAudible: '',
    NewBookAmazon: '',
    NewBookGoodreads: '',
    NewBookPublished: '',
    NewBookTag: '',
    NewBookPages: '',
    NewBookIsbn_10: '',
    NewBookIsbn_13: '',
    NewBookAsin: '',
    NewBookSeries: ''
  })

  constructor(private bookLink: BookLinkService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    // $( "input#NewBookPublished" ).datepicker();
  }

  SubmitNewBook(currentElement, event): void{
    let newBook: AvailableBook = new AvailableBook
    (
      '',
      this.NewBookData.value.NewBookTitle,
      this.NewBookData.value.NewBookAuthor,
      this.NewBookData.value.NewBookDescription,
      this.NewBookData.value.NewBookAudible,
      this.NewBookData.value.NewBookAmazon,
      this.NewBookData.value.NewBookGoodreads,
      new Date(),
      this.NewBookData.value.NewBookPublished,
      this.NewBookData.value.NewBookTag,
      this.NewBookData.value.NewBookPages,
      this.NewBookData.value.NewBookIsbn_10,
      this.NewBookData.value.NewBookIsbn_13,
      this.NewBookData.value.NewBookAsin,
      this.NewBookData.value.NewBookSeries,
      0,
      null
    );
    this.bookLink.saveBook(newBook).subscribe();
    this.NewBookData.reset();
    this.BookDialog(currentElement, event);
  }

  BookDialog(currentElement, event): void{
     $('div.NewBookDialog').toggle();
  }
}
