import { Component } from '@angular/core';
import {BooksComponentComponent} from "../books-component/books-component.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  public Books: BooksComponentComponent
}
