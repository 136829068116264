export class AvailableBook {
  public guid: string;
  public title: string;
  public author: string;
  public description: string;
  public audible: string;
  public amazon: string;
  public goodReads: string;
  public added: Date;
  public published: Date;
  public read: Date;
  public tag: string;
  public pages: number;
  public isbn_10: string;
  public isbn_13: string;
  public asin: string;
  public series: string;
  public calculatedScore: number;
  // public constructor(init?: Partial<AvailableBook>) {
  //   Object.assign(this, init);
  // }
  constructor(guid: string, title: string, author: string, description: string, audible: string, amazon: string, goodReads: string, added: Date, published: Date, tag: string, pages: number, isbn_10: string, isbn_13: string, asin: string, series: string, calculatedScore: number, read: Date) {
    this.guid = guid;
    this.title = title;
    this.author = author;
    this.description = description;
    this.audible = audible;
    this.amazon = amazon;
    this.goodReads = goodReads;
    this.added = added;
    this.published = published;
    this.tag = tag;
    this.pages = pages;
    this.isbn_10 = isbn_10;
    this.isbn_13 = isbn_13;
    this.asin = asin;
    this.series = series;
    this.calculatedScore = calculatedScore;
    this.read = read;
  }
}
