import { Injectable, Component, OnInit } from '@angular/core';
import {AvailableBook} from "../model/AvailableBook";
import {Observable, of, Subscription} from "rxjs";
import { HttpClient, HttpHeaders} from '@angular/common/http';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {Score} from "../model/Score";
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BookLinkService {
  //private serviceUrl: string = "https://localhost:5003/Book";
  private readonly serviceUrl: string = "";
  constructor(private http: HttpClient, private router: Router) {
    this.serviceUrl = location.hostname == "localhost" ? "https://localhost:5003/Book" : "https://wkynom2wq5.execute-api.us-east-1.amazonaws.com/default/Books_api";
  }

  getBooks(sortOrder: string, sortField: string, includeRead: boolean): Observable<AvailableBook[]>{
    let json = btoa(JSON.stringify([sortField, sortOrder, includeRead]));
    return this.http.get<AvailableBook[]>(this.serviceUrl + "?Method=GetBooks&Data=" + json);
  }

  saveBook(newBook: AvailableBook): Observable<string[]> {
    let json = btoa(JSON.stringify(newBook));
    return this.http.get<string[]>(this.serviceUrl + "?Method=SaveBook&Data=" + json);
  }

  saveScores(scores: Score[]): Observable<string[]> {
    let json = btoa(JSON.stringify(scores));
    return this.http.get<string[]>(this.serviceUrl + '?Method=SaveScores&Data=' + json);
    }
}
