import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ToolbarComponent} from "../toolbar/toolbar.component";
import {BooksComponentComponent} from "../books-component/books-component.component";
import * as $ from "jquery";
import {forEach} from "@angular-devkit/schematics";
import {BookLinkService} from "../book-link.service";
import {Score} from "../../model/Score";

@Component({
  selector: 'app-vote-books',
  templateUrl: './vote-books.component.html',
  styleUrls: ['./vote-books.component.css', '../toolbar/toolbar.css']
})
export class VoteBooksComponent implements OnInit {
  @Input() public Books: BooksComponentComponent;

  constructor(private bookLink: BookLinkService, private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  Vote(currentElement, event): void{
    var b = ($('input[name=Score]:checked').map(function(){return new Score($(this).closest('div.Vote').attr('data-bookid'), $(this).val());})).toArray();
    this.bookLink.saveScores(b).subscribe();
  }

}
