import {Component, Input, OnInit} from '@angular/core';
import {BooksComponentComponent} from "../books-component/books-component.component";
import * as $ from "jquery";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  @Input() public BooksComponent: BooksComponentComponent;
  constructor() { }

  ngOnInit() {
  }

  Scroll(id: string): void{
    $('div#'+id)[0].scrollIntoView();
  }
}
