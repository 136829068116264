import { Component, Input, OnInit } from '@angular/core';
import {AvailableBook} from "../../model/AvailableBook";
import * as $ from "jquery";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-book-component',
  templateUrl: './book-component.component.html',
  styleUrls: ['./book-component.component.css']
})
export class BookComponentComponent implements OnInit {

  @Input() public book:AvailableBook;
  constructor() { }

  ngOnInit(): void {
  }

  RadioChecks(currentElement, event): void {
    var rank = event.currentTarget.value;
    $("input[value='" + rank + "']").prop("checked", false);
    event.currentTarget.checked = true;
  }
}
