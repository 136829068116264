import {Component, Input, OnInit} from '@angular/core';
import * as $ from "jquery";
import {BookLinkService} from "../book-link.service";
import {AvailableBook} from "../../model/AvailableBook";
import {BooksComponentComponent} from "../books-component/books-component.component";

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css', '../toolbar/toolbar.css']
})
export class SortComponent implements OnInit {
  @Input() books: BooksComponentComponent;
  currentSortOrder: string = "DESC";
  constructor(private bookLink: BookLinkService) { }

  ngOnInit() {
  }

  Sort(currentElement, event): void {
    let newSortOrder = $(event.currentTarget).attr('data-sortorder');
    this.currentSortOrder = newSortOrder == null ? this.currentSortOrder : newSortOrder;
    let sortField = $('select#SortField option:selected').text();
    let includeRead = $('input#IncludeRead')[0].checked;
    this.bookLink.getBooks(this.currentSortOrder, sortField, includeRead)
      .subscribe(
        {
          next: books => {
            this.books.availableBooks = books;
          },
          complete: () =>
            setTimeout(function(){$('textarea.Convertible').each(function(){$(this).height(this.scrollHeight);})}, 0)
        });
  }
}
